<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <!-- <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">ID</div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  v-model="query.cdsid"
                  placeholder="请输入"
                  clearable
                />
              </div>
            </div> -->

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.dtableTitle1") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  v-model="query.departmentName"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.tableTite5") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.isEnabled"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    :label="$t('edpBackendCommon.statusNp')"
                    value="0"
                  ></el-option>
                  <el-option
                    :label="$t('edpBackendCommon.statusYes')"
                    value="1"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column fixed prop="id" label="ID" width="200">
              </el-table-column>

              <el-table-column
                prop="departmentCname"
                :label="$t('edpBackendEntry.formTit1')"
              >
              </el-table-column>

              <el-table-column
                prop="departmentEname"
                :label="$t('edpBackendEntry.tableTite2')"
              ></el-table-column>

              <el-table-column
                prop="isEnabled"
                :label="$t('edpBackendEntry.tableTite5')"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.isEnabled ? 'success' : 'danger'"
                    disable-transitions
                    >{{
                      scope.row.isEnabled
                        ? $t("edpBackendCommon.statusYes")
                        : $t("edpBackendCommon.statusNp")
                    }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="modifyTime"
                :label="$t('edpBackendEntry.dtableTitle2')"
              ></el-table-column>

              <el-table-column :label="$t('edpBackendCommon.operate')">
                <template slot-scope="scope">
                  <el-switch
                    @change="setStatus($event, scope.row)"
                    style="display: block; margin-bottom: 4px"
                    v-model="scope.row.isEnabled"
                    active-color="rgba(97, 191, 180, 1)"
                    inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>

                  <el-button type="text" @click="onPermit(scope.row)">
                    {{ $t("edpBackendRole.userPermit") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="query.current"
              :page-size="query.size"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      :title="dialogTitle_permit"
      width="900px"
      :visible.sync="permitDialog_show"
    >
      <div class="edp-backend-userPermitDialog">
        <section class="edp-backend-userPermitDialog-section">
          <div class="edp-backend-userPermitDialog-subTitle">
            {{ $t("edpBackendRole.permitDialogSubTitle1") }}
          </div>

          <div class="edp-backend-userPermitDialog-subContent">
            <div class="edp-backend-userPermitDialog-subContent-search">
              <el-input
                max="100"
                v-model="dataUserTreeSearch"
                :placeholder="$t('edpBackendRole.userTreeSearchPlaceholder')"
                clearable
              />
            </div>

            <el-tree
              :props="defaultProps"
              :data="dataUserTree"
              show-checkbox
              node-key="finalCode"
              ref="treeUserPermit"
              :default-checked-keys="dataUserTreeDefault"
              :filter-node-method="filterNode"
              @check-change="dataUserTreeChange"
              v-if="dataUserTreeShow"
            >
            </el-tree>
          </div>
        </section>

        <section class="edp-backend-userPermitDialog-section">
          <div class="edp-backend-userPermitDialog-subTitle">
            {{ $t("edpBackendRole.permitDialogSubTitle2") }}
          </div>

          <div class="edp-backend-userPermitDialog-subContent">
            <div
              class="edp-backend-userPermitDialog-alSeledList"
              v-for="(item, index) in userPermitTreeResult"
              :key="'userPermitTreeResult' + index"
            >
              <p>{{ item.finalName }}</p>

              <em @click="permitDialog_delete(item, index)">{{
                $t("edpBackendCommon.delete")
              }}</em>
            </div>
          </div>
        </section>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="permitDialog_show = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="permitDialog_save()">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "backendUser",
  data() {
    return {
      value2: true,
      pageList: [{}],
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      times: ["", ""],
      query: {
        current: 1,
        size: 10,
        departmentName: "",
        isEnabled: "",
      },

      dialogTitle_permit: this.$t("edpBackendRole.permitDialogTitle"),
      permitDialog_show: false,
      defaultProps: {
        children: "children",
        label: "finalName",
      },

      dataUserTree: [],
      userPermitTreeResult: [],
      dataUserTreeShow: false,
      dataUserTreeDefault: [],
      dataUserTree_selectId: "",
      dataUserTreeSearch: "",
    };
  },
  watch: {
    times(val) {
      if (val) {
        this.query.modifyTimeStart = val[0];
        this.query.modifyTimeEnd = val[1];
      } else {
        this.query.modifyTimeStart = "";
        this.query.modifyTimeEnd = "";
      }
    },
    dataUserTreeSearch(val) {
      this.$refs.treeUserPermit.filter(val);
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      deptList: "user/deptList",
      setDept: "user/setDept",
      deptPermitListApi: "backendRole/deptPermitListApi",
      deptPermitUpdateApi: "backendRole/deptPermitUpdateApi",
    }),
    async getList(val) {
      if (val) {
        this.query.current = val;
      }
      let params = { ...this.query };
      let res = await this.deptList(params);

      this.pageList = res.data.records;
      this.query.current = res.data.current;
      this.query.size = res.data.size;
      this.pageTotal = res.data.total;
    },
    onclear() {
      this.query = {
        current: 1,
        size: 10,
        departmentName: "",
        isEnabled: "",
      };
    },

    handleCurrentChange(val) {
      this.query.current = val;
      this.getList();
    },
    async setStatus(val, row) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        id: row.id,
        departmentId: row.departmentId,
        isEnabled: val,
      };
      let res = await this.setDept(params);

      if (res.success) {
        loading.close();
        this.getList(1);
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      } else {
        loading.close();
      }
    },

    pageTreeResultInit() {
      this.userPermitTreeResult = this.$refs.treeUserPermit.getCheckedNodes(
        true,
        true
      );
    },
    pageTreeInit(data) {
      data.forEach((val) => {
        this.$set(
          val,
          "finalName",
          val.finalName
            ? val.finalName
            : this.$i18n.locale === "en"
            ? val.englishName || val.name
            : val.name || val.englishName
        );
        this.$set(val, "finalCode", val.finalCode ? val.finalCode : val.code);

        let userLsAry = [];

        if (val.userList && val.userList.length > 0) {
          val.userList.forEach((val) => {
            this.$set(val, "finalName", val.fullName);
            this.$set(val, "finalCode", val.cdsid);

            if (val.selected) {
              this.userPermitTreeResult.push(val);
              this.dataUserTreeDefault.push(val.cdsid);
            }
          });

          userLsAry = JSON.parse(JSON.stringify(val.userList));
        }

        if (val.children && val.children.length > 0) {
          let childrenLsAry = JSON.parse(JSON.stringify(val.children));

          val.children = childrenLsAry.concat(userLsAry);

          this.pageTreeInit(val.children);
        } else {
          this.$set(val, "children", userLsAry);
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return (
        (data.finalName &&
          data.finalName.toLowerCase().indexOf(value.toLowerCase()) != -1) ||
        (data.cdsid && data.cdsid.indexOf(value) != -1)
      );
    },
    async permitDialogInit(id) {
      this.userPermitTreeResult = [];
      this.dataUserTreeDefault = [];
      this.dataUserTree_selectId = id;

      let res = await this.deptPermitListApi(id);

      this.dataUserTree = res.data && res.data.data ? res.data.data : [];

      this.pageTreeInit(this.dataUserTree);

      this.dataUserTreeShow = true;

      this.$forceUpdate();
    },
    async onPermit(data) {
      this.permitDialogInit(data.departmentId);

      this.permitDialog_show = true;
    },
    async permitDialog_save() {
      let seRsLs = [];

      this.userPermitTreeResult.forEach((val) => {
        if (val.cdsid) {
          seRsLs.push(val.cdsid);
        }
      });

      let params = {
        params: {
          reportId: this.dataUserTree_selectId,
          userCdsids: seRsLs,
        },
      };

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let res = await this.deptPermitUpdateApi(params);

      loading.close();

      if (res.data.success) {
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });

        this.permitDialog_show = false;
      }
    },
    dataUserTreeChange() {
      this.pageTreeResultInit();
    },
    permitDialog_delete(data, index) {
      this.userPermitTreeResult.splice(index, 1);

      this.dataUserTreeDefault.some((val, e) => {
        if (val == data.cdsid) {
          this.dataUserTreeDefault.splice(e, 1);
        }
      });

      this.$refs.treeUserPermit.setChecked(data.cdsid, false, true);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
